import React from "react"
// import { buildImageObj } from "../../lib/helpers"
// import { imageUrlFor } from "../../lib/image-url"
import { Link } from "gatsby"
import { getCategoryUrl } from "../../../lib/helpers"
import PortableText from "../../portableText"

function BlogPost(props) {
  const { _rawBody, title, mainImage, categories } = props
  return (
    <section>
      <article className="bg-[#F9FAFB] pb-20">
        {mainImage && mainImage.asset && (
          <div
            className="w-full min-h-[539px] bg-no-repeat object-top bg-cover bg-left  relative flex items-end"
            style={{ backgroundImage: `url("${mainImage.asset.url}")` }}
          >
            <div className="blog-image w-full h-full absolute opacity-60"></div>
            <div className=" md:w-10/12 lg:w-7/12 mx-auto flex flex-col z-20 mb-16 text-white">
              <h1 className={`  text-2xl md:text-[42px] md:leading-tight px-4`}>
                {title}
              </h1>
              <ul className="flex">
                {categories &&
                  categories.map(({ _id, title, slug }) => (
                    <li key={_id}>
                      <Link
                        to={getCategoryUrl(slug.current)}
                        className="flex items-center hover:underline"
                      >
                        <span className="mr-2">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.4882 7.06484L14.9679 1.56992C14.9415 1.28516 14.7148 1.06016 14.43 1.03203L8.9351 0.511719H8.92807C8.87182 0.511719 8.82787 0.529297 8.79447 0.562695L0.562637 8.79453C0.546341 8.81079 0.533413 8.83011 0.524592 8.85137C0.515771 8.87264 0.51123 8.89544 0.51123 8.91846C0.51123 8.94148 0.515771 8.96427 0.524592 8.98554C0.533413 9.0068 0.546341 9.02612 0.562637 9.04238L6.95756 15.4373C6.99096 15.4707 7.0349 15.4883 7.08236 15.4883C7.12982 15.4883 7.17377 15.4707 7.20717 15.4373L15.439 7.20547C15.4742 7.16855 15.4917 7.11758 15.4882 7.06484ZM7.08061 13.6725L2.32748 8.91934L9.35346 1.89336L13.6953 2.30469L14.1066 6.64648L7.08061 13.6725ZM10.9531 3.5C10.1005 3.5 9.40619 4.19434 9.40619 5.04688C9.40619 5.89941 10.1005 6.59375 10.9531 6.59375C11.8056 6.59375 12.4999 5.89941 12.4999 5.04688C12.4999 4.19434 11.8056 3.5 10.9531 3.5ZM10.9531 5.60938C10.6419 5.60938 10.3906 5.35801 10.3906 5.04688C10.3906 4.73574 10.6419 4.48438 10.9531 4.48438C11.2642 4.48438 11.5156 4.73574 11.5156 5.04688C11.5156 5.35801 11.2642 5.60938 10.9531 5.60938Z"
                              fill="#E94C28"
                            />
                          </svg>
                        </span>
                        <span className=" opacity-80">{title}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
        <div className=" md:w-10/12 lg:w-7/12 mx-auto">
          <div className=" mt-8 lg:mt-16 text-[#1D263A] px-4 ">
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </article>
    </section>
  )
}

export default BlogPost
