import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../components/templates/blogPost"
import EmailUs from "../components/EmailUs"
import Seo from "../components/seo"

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      mainImage {
        asset {
          url
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      categories {
        title
        _id
        slug {
          current
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const post = data && data.post
  return (
    <>
      {post && (
        <Seo title={post?.title || "Untitled"} image={post?.mainImage} />
      )}
      {post && <BlogPost {...post} />}
      <EmailUs />
    </>
  )
}
export default BlogPostTemplate
